/// <reference path='../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../node_modules/@types/bootbox/index.d.ts' />
/// <reference path='../../node_modules/@types/bootstrap/index.d.ts' />

declare var vlCustomerType: any;

const disclaimers = [
    {
        title: 'IMPORTANT WEBSITE MESSAGE',
        message: '<p style="text-align: justify;">Please be aware that you are entering a Value Line Retail website.</p>' +
            '<p style="text-align: justify;">Per our Company policy any individual who is affiliated with firms registered as brokers or investment advisors or are Financial Planners, ' +
            'CPA&apos;s, Legal Counsel, Government employees, or offer any type of financial product or service are considered Professional Clients and must have an Institutional subscription.</p> ' +
            '<p style="text-align: justify;">Please visit <a class= "badge badge-info" target="blank" href="https://www.valuelinepro.com">www.valuelinepro.com</a> to review our Professional services or contact us at ' +
            '<a class="badge badge-warning" href="mailto:is@valuelinepro.com">is@valuelinepro.com</a> or <a class="badge badge-warning" href="tel:+1-800-531-1425"><i class="fa fa-phone"></i> 1-800-531-1425</a> to speak to an Institutional Specialist.</p>' +
            '<p style="text-align: justify;">Orders are not final until accepted at the office of Value Line Publishing LLC at New York, NY. Value Line reserves the right to review all e-commerce orders for policy compliance.</p>' +
            '<footer class="blockquote-footer text-right">' +
            'Thank you.<br />' +
            '</footer>',
        size: 'large',
        show: false,
        closeButton: false,
    },
    {
        title: 'IMPORTANT WEBSITE MESSAGE',
        message: '<p style="text-align: justify;"><b>Reconfirming:</b> Please be aware that you are entering a Value Line Retail website.</p>' +
            '<p style="text-align: justify;">Per our Company policy any individual who is affiliated with firms registered as brokers or investment advisors or are Financial Planners, ' +
            'CPA&apos;s, Legal Counsel, Government employees, or offer any type of financial product or service are considered Professional Clients and must have an Institutional subscription.</p> ' +
            '<p style="text-align: justify;">Please visit <a class= "badge badge-info" target="blank" href="https://www.valuelinepro.com">www.valuelinepro.com</a> to review our Professional services or contact us at ' +
            '<a class="badge badge-warning" href="mailto:is@valuelinepro.com">is@valuelinepro.com</a> or <a class="badge badge-warning" href="tel:+1-800-531-1425"><i class="fa fa-phone"></i> 1-800-531-1425</a> to speak to an Institutional Specialist.</p>' +
            '<p style="text-align: justify;">Orders are not final until accepted at the office of Value Line Publishing LLC at New York, NY. Value Line reserves the right to review all e-commerce orders for policy compliance.</p>' +
            '<footer class="blockquote-footer text-right">' +
            'Thank you.<br />' +
            '</footer>',
        size: 'large',
        show: false,
        closeButton: false,
    },
    {
        title: 'IMPORTANT ORDER MESSAGE',
        message: '<p style="text-align: justify;">Please be aware that you are ordering a Value Line Retail subscription.</p>' +
            '<p style="text-align: justify;">Per our Company policy any individual who is affiliated with firms registered as brokers or investment advisors or are Financial Planners, ' +
            'CPA&apos;s, Legal Counsel, Government employees, or offer any type of financial product or service are considered Professional Clients and must have an Institutional subscription.</p> ' +
            '<p style="text-align: justify;">Please visit <a class= "badge badge-info" target="blank" href="https://www.valuelinepro.com">www.valuelinepro.com</a> to review our Professional services or contact us at ' +
            '<a class="badge badge-warning" href="mailto:is@valuelinepro.com">is@valuelinepro.com</a> or <a class="badge badge-warning" href="tel:+1-800-531-1425"><i class="fa fa-phone"></i> 1-800-531-1425</a> to speak to an Institutional Specialist.</p>' +
            '<p style="text-align: justify;">Orders are not final until accepted at the office of Value Line Publishing LLC at New York, NY. Value Line reserves the right to review all e-commerce orders for policy compliance.</p>' +
            '<footer class="blockquote-footer text-right">' +
            'Thank you.<br />' +
            '</footer>',
        size: 'large',
        show: false,
        closeButton: false,
    }
];

function createCookie(name: string, value: string, days: number) {

    let expires = ""
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    let host = location.host;
    if (host.split('.').length === 1) {
        // no "." in a domain - it's localhost or something similar
        const localCookie = name + "=" + value + expires + "; path=/";
        //console.log(localCookie);
        document.cookie = localCookie;
    }
    else {
        // Remember the cookie on all subdomains.       
        let domainParts = host.split('.');
        domainParts.shift();
        let domain = '.' + domainParts.join('.');
        let vlSubDomainCookie = name + "=" + value + expires + "; path=/; domain=" + domain;
        //console.log(vlSubDomainCookie);
        document.cookie = vlSubDomainCookie

        // check if cookie was successfuly set to the given domain
        // (otherwise it was a Top-Level Domain)
        if (readCookie(name) == null || readCookie(name) != value) {
            // append "." to current domain
            domain = '.' + host;

            let vlTopDomainCookie = name + "=" + value + expires + "; path=/; domain=" + domain;
            //console.log(vlTopDomainCookie);
            document.cookie = vlTopDomainCookie
        }
    }
}

function readCookie(name: string) {
    let nameEQ = name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

$(document).ready(function () {

    //let proUrl = 'https://www.valuelinepro.com/';
    //console.log('Before set: ' + proUrl);
    //if (vlCustomerType === 'pro') {
    //    proUrl = 'https://research.valueline.com/secure/dashboard';
    //    console.log('After set: ' + proUrl);
    //}

    //let cName = "VL-Browse";
    //let visited = readCookie(cName);
    //if (!visited || visited !== "true") {
    //    //console.log('Show dialog');

    //    let disc = disclaimers[0];

    //    const dscOneDialog = bootbox.dialog({
    //        title: disc.title,
    //        message: disc.message,
    //        size: "large",
    //        show: disc.show,
    //        closeButton: false,
    //        buttons: {
    //            pro: {
    //                label: "I'm a Professional",
    //                className: 'btn-info',
    //                callback: function () {
    //                    //console.log("Pro clicked");
    //                    window.location.assign(proUrl);
    //                }
    //            },
    //            retail: {
    //                label: "I'm a Retail Customer",
    //                className: 'btn-success',
    //                callback: function () {
    //                    //console.log("Retail clicked");

    //                    let cDays = 15;

    //                    createCookie(cName, "true", cDays);
    //                    //console.log('Cookie Set');

    //                }
    //            }
    //        }
    //    });


    //    dscOneDialog.modal("show");
    //} else {
    //    //console.log('Cookie One Exists')
    //}

});

//function showDisclaimerLoginRegister(event: any) {
//    //console.log('Showing disclaimer...');
//    event.preventDefault();
//    event.stopImmediatePropagation();
//    let callingElement = $(event.target);

//    let cName = "VL-LoginRegister";
//    let disc = disclaimers[1];

//    let visited = readCookie(cName);

//    let proUrl = 'https://www.valuelinepro.com/';
//    console.log('Before set: ' + proUrl);
//    if (vlCustomerType === 'pro') {
//        proUrl = 'https://research.valueline.com/secure/dashboard';
//        console.log('After set: ' + proUrl);
//    }

//    if (!visited || visited !== "true" && disc) {
//        //console.log('Show dialog');

//        const dscTwoDialog = bootbox.dialog({
//            title: disc.title,
//            message: disc.message,
//            size: "large",
//            show: disc.show,
//            closeButton: false,
//            buttons: {
//                pro: {
//                    label: "I'm a Professional",
//                    className: 'btn-info',
//                    callback: function () {
//                        //console.log("Pro clicked");
//                        window.location.assign(proUrl);
//                    }
//                },
//                retail: {
//                    label: "I'm a Retail Customer",
//                    className: 'btn-success',
//                    callback: function () {
//                        //console.log("Retail clicked");
//                        let cDays = 15;
//                        createCookie(cName, "true", cDays);
//                        //console.log('Cookie Set');
//                        cleanupAndRedirect(callingElement);
//                    }
//                }
//            }
//        });

//        dscTwoDialog.modal("show");


//    } else {
//        cleanupAndRedirect(callingElement);
//    }
//}

function showDisclaimerAddToCart(event: any) {
    //console.log('Showing disclaimer...');
    event.preventDefault();
    event.stopImmediatePropagation();
    let callingElement = $(event.target);

    //var cName = "VL-AddToCart";
    let cName = "VL-LoginRegister";
    let disc = disclaimers[2];

    let visited = readCookie(cName);

    let proUrl = 'https://www.valuelinepro.com/';
    console.log('Before set: ' + proUrl);
    if (vlCustomerType === 'pro') {
        proUrl = 'https://research.valueline.com/secure/dashboard';
        console.log('After set: ' + proUrl);
    }
    if (!visited || visited !== "true" && disc) {
        //console.log('Show dialog');


        const dscTwoDialog = bootbox.dialog({
            title: disc.title,
            message: disc.message,
            size: "large",
            show: disc.show,
            closeButton: false,
            buttons: {
                pro: {
                    label: "I'm a Professional",
                    className: 'btn-info',
                    callback: function () {
                        //console.log("Pro clicked");
                        window.location.assign(proUrl);
                    }
                },
                retail: {
                    label: "I'm a Retail Customer",
                    className: 'btn-success',
                    callback: function () {
                        //console.log("Retail clicked");
                        let cDays = 15;

                        createCookie(cName, "true", cDays);
                        //console.log('Cookie Set');
                        cleanupAndRedirect(callingElement);
                        callingElement.trigger('click');
                    }
                }
            }
        });

        dscTwoDialog.modal("show");


    } else {
        cleanupAndRedirect(callingElement);
    }
}

function cleanupAndRedirect(elemnt: JQuery) {
    let href = elemnt.attr('href');

    if (href) {
        let hotToCookies = $('.cookie-hot');
        if (hotToCookies) {
            hotToCookies.each(function () {
                $(this).removeClass('cookie-hot');
            });
        }
        window.location.href = href
    }

    let addToCartBusstons = $('button.add-to-cart');
    if (addToCartBusstons) {
        addToCartBusstons.each(function () {
            $(this).removeAttr("onclick");
        });
    }
}

$(function () {

    //Add event to COOKIE HOT elements
    //$(document).on("click", ".cookie-hot", showDisclaimerLoginRegister);

    $(document).ajaxComplete(function () {
        let registerCookie = "VL-LoginRegister";
        let registerVisited = readCookie(registerCookie);
        if (registerVisited) {
            let hotToCookies = $('.cookie-hot');
            if (hotToCookies) {
                hotToCookies.each(function () {
                    $(this).removeClass('cookie-hot');
                });
            }

            let addToCartBusstons = $('button.add-to-cart');

            if (addToCartBusstons) {
                addToCartBusstons.each(function () {
                    $(this).removeAttr("onclick");
                });
            }
        } else {
            $("div.login-widget > a").removeClass("cookie-hot");
            $("div.login-widget > a").addClass("cookie-hot");
        }
    });

    //$(document)
    //    .ajaxStart(function () {
    //        $('.vl-spinner').show();
    //    }).ajaxStop(function () {

    //        $('.vl-spinner').hide();
    //    });
});

